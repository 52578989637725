@use 'sass:math';
@import '../../../../../packages/photon/theme/global';

.root {
  display: flex;
  flex-direction: column;
}

.logo-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 (-$spacing-xxs);

  & > .is-vertically-centered {
    align-items: 'center';
  }
}

.logo-item {
  flex: 0 0 calc(50% - $spacing-xs);
  margin: 0 $spacing-xxs;

  @media (min-width: $mq-medium-and-above) {
    flex-basis: calc(25% - $spacing-xs);
  }
}

.stage-container {
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: $mq-medium-and-above) {
    flex-direction: row;
    /* stylelint-disable-next-line meowtec/no-px */
    height: 600px;
  }
}

.content-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: $color-moss;

  @media (min-width: $mq-medium-and-above) {
    justify-content: flex-end;
    width: 50%;
  }
}

.selling-points-section {
  &.bg-white {
    background-color: $color-white;
  }

  &.bg-faded-gray {
    background-color: $color-faded-gray;
  }
}

.selling-points-container {
  margin-bottom: -4.5rem;

  @include content-container;

  & > div {
    transform: translateY(-4.5rem);
  }
}

.text-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: $breakpoint-large + px;
  height: 100%;
  gap: $spacing-m;
  padding: 2rem;
  padding-bottom: 8rem;
  padding-inline: $spacing-xs;

  @media (min-width: $mq-medium-and-above) {
    justify-content: space-between;
    max-width: math.div($breakpoint-large, 2) + px;
    padding-top: 4rem;
    padding-inline: $spacing-xl;
  }

  .text-intro {
    color: $color-white;
  }

  & > .logo-items {
    display: none;
    flex-wrap: nowrap;
    justify-content: flex-start;

    @media (min-width: $mq-small-and-above) {
      display: flex;
      max-width: 50%;
    }

    @media (min-width: $mq-medium-and-above) {
      max-width: 70%;
    }
  }

  .logo-item {
    flex-basis: auto;
    flex-shrink: 1;
  }
}

.text-intro {
  h1 {
    /* stylelint-disable-next-line meowtec/no-px */
    font-size: 24px;

    @media (min-width: $mq-small-and-above) {
      /* stylelint-disable-next-line meowtec/no-px */
      font-size: 40px;
    }

    @media (min-width: $mq-medium-and-above) {
      /* stylelint-disable-next-line meowtec/no-px */
      font-size: 42px;
    }
  }
}

.list-item {
  display: flex;
  align-items: center;
  width: 100%;
  color: white;
  list-style: none;
  gap: $spacing-xs;

  br {
    display: none;
  }
}

.headline-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: $spacing-s;
}

.image-container {
  align-items: center;
  justify-content: center;
  width: 100%;
  /* stylelint-disable-next-line meowtec/no-px */
  height: 180px;

  @media (min-width: $mq-medium-and-above) {
    display: flex;
    width: 50%;
    height: 100%;
  }
}

@each $color-name, $color-value in $cms-colors {
  .bg-#{'' + $color-name} {
    @include background-text-color($color-value);
  }
}
